import {
  Box,
  Button,
  Popover,
  PopoverOrigin,
  PopoverPosition,
  PopoverReference,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { Close } from "../../../icons/close";

//follow documentation https://mui.com/material-ui/react-popover/
interface ICPopover {
  open: boolean;
  close: () => void;
  title: string | ReactNode;
  content: JSX.Element | ReactNode;
  anchorEl: HTMLButtonElement | null;
  buttonId: string | undefined;
  anchorOrigin?: PopoverOrigin | undefined;
  anchorPosition?: PopoverPosition | undefined;
  anchorReference?: PopoverReference | undefined;
  sxBoxTitle?: any;
}

const boxWrapper = {
  width: "auto",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: "0px 25px 50px rgba(100, 116, 139, 0.25)",
};

const boxTitle = {
  backgroundColor: "#29A776",
  p: 3,
  color: "#FFFFFF",
  display: "flex",
  justifyContent: "space-between",
  fontSize: "1.8rem",
  fontWeight: 600,
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
};

const boxContent = {
  backgroundColor: "#FFFFFF",
  px: 3,
  py: 4,
  borderRadius: "8px",
};

const CustomPopover = ({
  open,
  close,
  title,
  content,
  anchorEl,
  buttonId,
  anchorOrigin,
  anchorPosition,
  anchorReference,
  sxBoxTitle,
}: ICPopover) => {
  return (
    <Popover
      id={buttonId}
      anchorReference={anchorReference}
      open={open}
      onClose={close}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      anchorPosition={anchorPosition}
      sx={{
        marginTop: "0.2rem",
        "& > div": {
          borderWidth: 0,
        },
      }}
    >
      <Box sx={boxWrapper}>
        <Box sx={{ ...boxTitle, ...sxBoxTitle }}>
          <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>
            {title}
          </Typography>
          <Button
            color="inherit"
            onClick={close}
            sx={{
              padding: 0,
              minWidth: "14px",
            }}
            variant="text"
          >
            {<Close />}
          </Button>
        </Box>
        <Box sx={boxContent}>{content}</Box>
      </Box>
    </Popover>
  );
};

export default CustomPopover;
