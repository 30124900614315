import { createSvgIcon } from "@mui/material/utils";

export const Eco = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.04171 6.70825C2.76671 8.98325 2.76671 12.6666 5.02505 14.9416C6.25005 12.1083 8.43338 9.74159 11.1584 8.33325C8.85005 10.2833 7.23338 13.0083 6.66671 16.0999C8.83338 17.1249 11.5 16.7499 13.2917 14.9583C16.1917 12.0583 16.6667 3.33325 16.6667 3.33325C16.6667 3.33325 7.94171 3.80825 5.04171 6.70825Z"
      fill="currentColor"
    />
  </svg>,
  "Eco"
);
