import { FC } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../../redux/app_store";
// import { Container } from './styles';

const SubscriptionWrapper: FC<any> = ({ premium, main }) => {
  const { userFlags } = useSelector((store: IReduxState) => store);

  return userFlags?.tier === "premium" ||
    userFlags?.tier === "pro" ||
    userFlags?.tier === "trial"
    ? premium
    : main;
};

export default SubscriptionWrapper;
