import { useEffect, useMemo, useRef, useState } from "react";

import { useRouter } from "next/router";

import { useTranslation } from "react-i18next";
import { IReduxState } from "../../../redux/app_store";
import {
  Box,
  Divider,
  Drawer,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useMediaQuery,
} from "@mui/material";

import type { Theme } from "@mui/material";
import { Scrollbar } from "../../share/scrollbar";
import ParslyIcon from "../../../../public/img/icons/parsly-logo.svg";
import { OutletPopover } from "./outlet-popover";
import { Selector as SelectorIcon } from "../../../icons/selector";
import AppButton from "../../share/app-button";
import { getSections } from "./sidebar-list";
import { DashboardSidebarSection } from "./dashboard-sidebar-section";
import TawkTo from "../../../utils/chat";
import config from "../../../utils/config";
import NextLink from "next/link";
import { EAPP_ROUTE } from "../../../enums/route.enum";
import { useSelector } from "react-redux";
import { Cog as CogIcon } from "../../../icons/cog";
import SubscriptionWrapper from "../../hoc/subscription-wrapper";
import TierLabel from "../../custom/tier-label/tier-label";

interface DashboardSidebarProps {
  onClose?: () => void;
  open?: boolean;
}
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    fontSize: "1rem",
    border: "1px solid #dadde9",
  },
}));

const DashboardSidebar = ({ onClose, open }: DashboardSidebarProps) => {
  const router = useRouter();

  const { selectedOutlet, selectedUnit, outlets } = useSelector(
    (store: IReduxState) => store
  );

  const { t } = useTranslation();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const organizationsRef = useRef<HTMLButtonElement | null>(null);

  const sections = useMemo(() => getSections(t), [t, selectedUnit?.role]);

  const [activeChat, setActiveChat] = useState<boolean>(false);

  const [openOrganizationsPopover, setOpenOrganizationsPopover] =
    useState<boolean>(false);
  const [disabledOutletSwitch, setDisabledOutletSwitch] =
    useState<boolean>(false);

  const [disabledMenu, setDisabledMenu] = useState<boolean>(
    router.pathname.startsWith("/setting/outlet")
  );

  const tawk = new TawkTo(
    config.tawktoPropertyId!,
    config.tawktoWidgetIdNew!,
    1000
  );

  useEffect(() => {
    if (router.pathname !== EAPP_ROUTE.SHARE_SUBSCRIPTION_PAGE) {
      tawk &&
        tawk.onLoad(() => {
          setActiveChat(true);
        });
    }
  }, []);

  useEffect(() => {
    tawk && tawk.hideWidget();
  });

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose?.();
    }
  };

  useEffect(handlePathChange, [router.isReady, router.asPath]);
  useEffect(() => setDisabledOutletSwitch(false), [router.pathname]);
  useEffect(
    () => setDisabledMenu(router.pathname.startsWith("/setting/outlet")),
    [router.pathname]
  );

  const handleCloseDrawer = (): void => {
    if (open) {
      onClose?.();
    }
  };

  const handleOpenOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(true);
  };

  const handleCloseOrganizationsPopover = (): void => {
    setOpenOrganizationsPopover(false);
  };

  const content = (
    <>
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                margin={3}
              >
                <NextLink href={EAPP_ROUTE.MAIN} passHref>
                  <Box
                    onClick={handleCloseDrawer}
                    sx={{
                      cursor: "pointer",
                      fontSize: 0,
                      minWidth: "138px",
                      minHeight: "32px",
                    }}
                    data-testid="parsly-logo-sidebar"
                  >
                    <ParslyIcon />
                  </Box>
                </NextLink>
                <SubscriptionWrapper
                  premium={
                    <TierLabel
                      isPremium
                      outletName={selectedOutlet?.displayName}
                    />
                  }
                  main={<TierLabel outletName={selectedOutlet?.displayName} />}
                />
              </Box>
            </Box>
            <Box sx={{ px: 2 }}>
              <HtmlTooltip
                title={
                  disabledOutletSwitch
                    ? t("application_utils__outlet_switcher_disabled_tooltip")
                    : ""
                }
                placement="bottom"
              >
                <Box
                  onClick={() =>
                    !disabledOutletSwitch
                      ? handleOpenOrganizationsPopover()
                      : null
                  }
                  ref={organizationsRef}
                  className="outletSelector"
                  sx={{
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    cursor: !disabledOutletSwitch ? "pointer" : "default",
                    color: !disabledOutletSwitch
                      ? "rgba(55, 65, 81, 0.48)"
                      : "unset",
                    display: "flex",
                    justifyContent: "space-between",
                    px: 3,
                    py: "11px",
                    borderRadius: 1,
                  }}
                >
                  <Box>
                    <Typography
                      color={
                        disabledOutletSwitch
                          ? "rgba(55, 65, 81, 0.48)"
                          : "neutral.800"
                      }
                      variant="subtitle1"
                      data-testid="selected-outlet"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {disabledMenu ? (
                        <CogIcon
                          fontSize="large"
                          sx={{ mr: "0.2rem", color: "#6B7280" }}
                        />
                      ) : null}
                      {disabledMenu
                        ? t("outlet_settings")
                        : !selectedOutlet && outlets.length <= 0
                        ? t("no_available_outlets")
                        : selectedOutlet?.displayName}
                    </Typography>
                  </Box>
                  <SelectorIcon
                    sx={{
                      color: disabledOutletSwitch
                        ? "rgba(55, 65, 81, 0.48)"
                        : "neutral.600",
                      width: 10,
                      height: 10,
                    }}
                  />
                </Box>
              </HtmlTooltip>
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: "neutral.400", // dark divider
              my: "2.4rem!important",
            }}
          />
          <Box
            sx={{ flexGrow: 1, px: "1.6rem", position: "relative" }}
            className="navigation"
          >
            {sections.map((section) => (
              <DashboardSidebarSection
                key={section.title}
                path={router.asPath}
                closeDrawerTrigger={handleCloseDrawer}
                {...section}
                disabled={disabledMenu}
              />
            ))}
          </Box>
          <Divider
            sx={{
              borderColor: "neutral.400", // dark divider
            }}
          />
          <Box sx={{ p: 2, zIndex: 3 }}>
            <Typography
              color="neutral.800"
              variant="subtitle2"
              data-testid="need_help"
            >
              {t("need_help")}
            </Typography>
            <AppButton
              onClick={() => router.push(`/tutorials`)}
              color="primary"
              fullWidth={true}
              sx={{ mt: 2 }}
              variant="outlined"
              data-testid="video_tutorial"
              className="howToSection"
            >
              {t("video_tutorial_button")}
            </AppButton>
            <AppButton
              disabled={!activeChat}
              onClick={() => {
                tawk && tawk.toggle();
              }}
              color="primary"
              fullWidth={true}
              sx={{ mt: 2 }}
              variant="contained"
              data-testid="chat"
            >
              {t("chat")}
            </AppButton>
          </Box>
        </Box>
      </Scrollbar>
      <OutletPopover
        anchorEl={organizationsRef.current}
        onClose={handleCloseOrganizationsPopover}
        open={openOrganizationsPopover}
        inOutletSettings={disabledMenu}
      />
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            backgroundColor: "neutral.300",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: "#FFFFFF",
            width: 280,
          },
        }}
        sx={{ display: !open ? "block" : "none" }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <>
      <Drawer
        anchor="left"
        onClose={onClose}
        ModalProps={{
          keepMounted: true,
        }}
        open={router.pathname.startsWith("/setting/") ? !open : open}
        PaperProps={{
          sx: {
            backgroundColor: "neutral.300",
            color: "#FFFFFF",
            width: 280,
          },
        }}
        sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
        variant="temporary"
      >
        {content}
      </Drawer>
    </>
  );
};

export default DashboardSidebar;
