import { createSvgIcon } from "@mui/material/utils";

export const Close = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6667 2.68337L21.3167 0.333374L12 9.65004L2.68337 0.333374L0.333374 2.68337L9.65004 12L0.333374 21.3167L2.68337 23.6667L12 14.35L21.3167 23.6667L23.6667 21.3167L14.35 12L23.6667 2.68337Z"
      fill="currentColor"
    />
  </svg>,
  "Close"
);
