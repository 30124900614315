// File used to 'dump' color differences that should not be integrated into MUI theme

const primary = {
  states: {
    hover: "#29A77614",
    hover2:
      "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F0968E;",
  },
};

const graph = [
  "#ffb129",
  "#9ca3af",
  "#f08080",
  "#feeac5",
  "#e59c9a",
  "#82b08d",
  "#c18488",
  "#f97d49",
  "#a7d2bd",
  "#c4c4c4”,”#ecccc6",
];

export { primary, graph };
