import { useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";

const usePremiumColor = () => {
  const { userFlags } = useSelector((store: IReduxState) => store);

  const colorMap = {
    premium: { primary: "#29A776", secondary: "#FFF" },
    pro: { primary: "#FDCC18", secondary: "#000" },
    trial: { primary: "#F24968", secondary: "#FFF" },
    default: { primary: "#6B7280", secondary: "#FFF" },
  };

  return colorMap[userFlags?.tier] || colorMap["default"];
};

export { usePremiumColor };
