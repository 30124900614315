import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";

export const useAccountOwner = () => {
  const { user } = useSelector((store: IReduxState) => store);
  const token = jwt_decode(localStorage.getItem("accessToken")!);
  const currentAccId = token!["https://parsly.com/account_id"];

  const account: any = user?.accounts?.filter((el) => el.id === currentAccId);

  return !!account ? user?.id === account[0]?.owner : false;
};
