import { useEffect, useState } from "react";
import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { DashboardNavbar } from "./dashboard-navbar";
import DashboardSidebar from "./dashboard-sidebar";

import { Box } from "@mui/material";
import { useRouter } from "next/router";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = styled("div")<{
  width?: string;
}>(({ theme, width }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: width || 280,
  },
}));

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    !router.pathname.startsWith("/setting/") ? false : true
  );
  const [isSettingPage, setIsSettingPage] = useState<boolean>(false);

  /*   useEffect(() => {
    setIsSettingPage(
      router.pathname === EAPP_ROUTE.SETTING_OUTLET ||
        router.pathname === EAPP_ROUTE.SETTING_ACCOUNT
    );
  }, [router.pathname]); */

  useEffect(() => {
    setIsSidebarOpen(!router.pathname.startsWith("/setting/") ? false : true);
  }, [router]);

  return (
    <>
      <DashboardLayoutRoot width={isSidebarOpen ? "0px" : "280px"}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar
        isSettingOutletPage={isSettingPage}
        onOpenSidebar={(): void => {
          setIsSettingPage(!isSettingPage);
          setIsSidebarOpen(!isSidebarOpen);
        }}
        width={isSidebarOpen ? "0px" : "280px"}
      />
      <DashboardSidebar
        onClose={(): void => {
          setIsSidebarOpen(!isSidebarOpen);
          setIsSettingPage(!isSettingPage);
        }}
        open={isSidebarOpen}
      />
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
