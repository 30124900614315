import { createSvgIcon } from "@mui/material/utils";

export const Dashboard = createSvgIcon(
  <svg
    viewBox="0 0 18 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.707 0.293C8.51947 0.105529 8.26516 0.000213623 8 0.000213623C7.73483 0.000213623 7.48053 0.105529 7.293 0.293L0.292999 7.293C0.110841 7.4816 0.0100465 7.7342 0.0123249 7.9964C0.0146034 8.2586 0.119772 8.50941 0.30518 8.69482C0.490589 8.88023 0.741401 8.9854 1.0036 8.98767C1.26579 8.98995 1.5184 8.88916 1.707 8.707L2 8.414V15C2 15.2652 2.10536 15.5196 2.29289 15.7071C2.48043 15.8946 2.73478 16 3 16H5C5.26522 16 5.51957 15.8946 5.70711 15.7071C5.89464 15.5196 6 15.2652 6 15V13C6 12.7348 6.10536 12.4804 6.29289 12.2929C6.48043 12.1054 6.73478 12 7 12H9C9.26522 12 9.51957 12.1054 9.70711 12.2929C9.89464 12.4804 10 12.7348 10 13V15C10 15.2652 10.1054 15.5196 10.2929 15.7071C10.4804 15.8946 10.7348 16 11 16H13C13.2652 16 13.5196 15.8946 13.7071 15.7071C13.8946 15.5196 14 15.2652 14 15V8.414L14.293 8.707C14.4816 8.88916 14.7342 8.98995 14.9964 8.98767C15.2586 8.9854 15.5094 8.88023 15.6948 8.69482C15.8802 8.50941 15.9854 8.2586 15.9877 7.9964C15.99 7.7342 15.8892 7.4816 15.707 7.293L8.707 0.293Z"
      fill="currentColor"
    />
  </svg>,
  "Dashboard"
);
