import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import PremiumLockIllustration from "../../../../public/img/premium-lock-illustration.svg";
// import AppButton from "../../share/app-button";
import CustomPopover from "../custom-popover/CustomPopover";
import AppButton from "../../share/app-button";
import { useRouter } from "next/router";
import { EAPP_ROUTE } from "../../../enums/route.enum";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { useAccountOwner } from "../../../hooks/use-account-owner";
import { usePremiumColor } from "../../../hooks/use-premium";

interface ITierLabel {
  isPremium?: boolean;
  outletName: string | undefined;
}

const innerContent = {
  backgroundColor: "rgba(41, 167, 118, 0.08)",
  display: "flex",
  alignItems: "center",
  p: 2,
  marginTop: 2,
  borderRadius: "8px",
};

const textWrapper = {
  width: "215px",
  "& p": {
    fontWeight: 500,
    fontSize: "16px",
  },
  "& span": {
    fontWeight: 700,
  },
};

const TierLabel = ({ isPremium, outletName }: ITierLabel) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { userFlags } = useSelector((store: IReduxState) => store);
  const showPlansButton = useAccountOwner();
  const premiumColor = usePremiumColor();
  const router = useRouter();
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const tierLabel =
    userFlags?.tier === "pro"
      ? t("user_tier_label__pro")
      : userFlags?.tier === "premium"
      ? t("user_tier_label__premium")
      : userFlags?.tier === "trial"
      ? t("user_tier_label__trial")
      : t("user_tier_label__main");

  const renderModalContent = () => {
    if (isPremium) {
      const datetime1: any = new Date(); // Replace with your first datetime
      const datetime2: any = new Date(userFlags?.stripe?.currentPeriodEndAt); // Replace with your second datetime

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = Math.abs(datetime2 - datetime1);

      // Calculate the number of days
      const daysDifference = Math.ceil(
        timeDifferenceMs / (1000 * 60 * 60 * 24)
      );

      return (
        <Box>
          <Box sx={{ ...innerContent, flexDirection: "column" }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={textWrapper}>
                <ul>
                  <li>
                    <Typography>
                      {t("user_tier_label__features__option_3")}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {t("user_tier_label__features__option_4")}
                    </Typography>
                  </li>
                </ul>
              </Box>
              <Box sx={{ width: "115px", height: "100%" }}>
                <PremiumLockIllustration />
              </Box>
            </Box>

            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ width: "100%", mt: 1, maxWidth: "300px" }}
            >
              <Typography>
                {t("user_tier_label__your_current_plan_ends", {
                  days: daysDifference,
                })}
              </Typography>
            </Box>
            {showPlansButton && userFlags?.tier === "trial" && (
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ width: "100%", mt: 1 }}
              >
                <AppButton
                  variant="contained"
                  onClick={() => {
                    handleClose();
                    router.push(EAPP_ROUTE.SUBSCRIPTION_PAGE);
                  }}
                  sx={{
                    backgroundColor: premiumColor?.primary,
                  }}
                >
                  {t("user_tier_label__tell_me_more_bttn")}
                </AppButton>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <Box>
        <Typography
          sx={{ fontWeight: 600, fontSize: "1.8rem" }}
        >{`${outletName!} ${t(
          "user_tier_label__middle_text_current_plan"
        )} Parsly ${tierLabel}`}</Typography>
        <Box sx={{ ...innerContent, flexDirection: "column" }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={textWrapper}>
              <ul>
                <li>
                  <Typography>
                    {t("user_tier_label__features__option_1")}
                  </Typography>
                </li>
                <li>
                  <Typography>
                    {t("user_tier_label__features__option_2")}
                  </Typography>
                </li>
              </ul>
            </Box>
            <Box sx={{ width: "115px", height: "100%" }}>
              <PremiumLockIllustration />
            </Box>
          </Box>
          {showPlansButton && (
            <Box
              display="flex"
              justifyContent="flex-end"
              sx={{ width: "100%", mt: 1 }}
            >
              <AppButton
                variant="contained"
                onClick={() => {
                  handleClose();
                  router.push(EAPP_ROUTE.SUBSCRIPTION_PAGE);
                }}
                sx={{
                  backgroundColor: premiumColor?.primary,
                }}
              >
                {t("user_tier_label__tell_me_more_bttn")}
              </AppButton>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const openTierLabelModal = Boolean(anchorEl);
  const id = openTierLabelModal ? "simple-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        sx={{
          background: premiumColor?.primary,
          color: premiumColor?.secondary,
          cursor: isPremium ? "default" : "pointer",
          fontSize: "1.2rem",
          padding: "0.2rem 0.8rem",
          ":hover": {
            background: premiumColor.primary,
          },
        }}
        onClick={handleOpen}
      >
        {tierLabel}
      </Button>
      <CustomPopover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        buttonId={id}
        close={handleClose}
        sxBoxTitle={{
          backgroundColor: premiumColor?.primary,
        }}
        content={renderModalContent()}
        open={openTierLabelModal}
        title={
          userFlags?.tier
            ? t(`user_tier_label__modal_${userFlags?.tier}_title`)
            : t("user_tier_label__modal_starter_title")
        }
      />
    </>
  );
};

export default TierLabel;
