// import { useState } from "react";
import type { FC, ReactNode } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";
import { Box, Button, /* Collapse, */ ListItem } from "@mui/material";
import type { ListItemProps } from "@mui/material";
// import { ChevronDown as ChevronDownIcon } from "../../../icons/chevron-down";
// import { ChevronRight as ChevronRightIcon } from "../../../icons/chevron-right";
import { primary } from "../../../theme/colors";

interface DashboardSidebarItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  chip?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  disable?: boolean;
  closeDrawerTrigger: Function;
}

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = (props) => {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    closeDrawerTrigger,
    disable,
    ...other
  } = props;
  // const [open, setOpen] = useState<boolean>(!!openProp);

  // const handleToggle = (): void => {
  //   setOpen((prevOpen) => !prevOpen);
  // };

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: "block",
          mb: 0.5,
          py: 0,
          // px: 2,
        }}
        {...other}
      >
        <NextLink href={path as string} passHref>
          <Button
            // endIcon={
            //   !open ? (
            //     <ChevronRightIcon fontSize="small" />
            //   ) : (
            //     <ChevronDownIcon fontSize="small" />
            //   )
            // }
            disableRipple
            // onClick={handleToggle}
            data-testid={title}
            startIcon={icon}
            disabled={disable}
            sx={{
              borderRadius: 20,
              color: active ? "primary.dark" : "text.primary",
              justifyContent: "flex-start",
              pl: `${paddingLeft}px`,
              pr: 3,
              mb: 0.5,
              textAlign: "left",
              textTransform: "none",
              width: "100%",
              ...(active && {
                backgroundColor: primary.states.hover,
                color: "primary.dark",
                fontWeight: "fontWeightBold",
              }),
              "&:hover": {
                backgroundColor: primary.states.hover,
              },
              "& .MuiButton-startIcon": {
                color: active ? "primary.dark" : "text.secondary",
              },
              "& .MuiButton-endIcon": {
                color: "text.secondary",
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
        </NextLink>
        {/* <Collapse in={open} sx={{ mt: 0.5 }}> */}
        <Box sx={{ mr: -2, px: 2 }}>{children}</Box>
        {/* </Collapse> */}
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        // px: 2,
      }}
      data-testid={title}
    >
      <NextLink href={path as string} passHref>
        <Button
          component="a"
          onClick={() => closeDrawerTrigger()}
          startIcon={icon}
          endIcon={chip}
          disableRipple
          disabled={disable}
          sx={{
            borderRadius: 20,
            color: "text.primary",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: 3,
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            ...(active && {
              backgroundColor: primary.states.hover,
              color: "primary.dark",
              fontWeight: "fontWeightBold",
            }),
            "& .MuiButton-startIcon": {
              color: disable
                ? "inheritreact-router-dom"
                : active
                ? "primary.dark"
                : "text.secondary",
            },
            "&:hover": {
              backgroundColor: primary.states.hover,
            },
          }}
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
      </NextLink>
    </ListItem>
  );
};

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  disable: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
};
